.mathquill4quill-mathquill-input {
  border: 1px solid #ccc;
  font-size: 16px;
  min-height: 26px;
  margin: 0;
  padding: 3px 5px;
  width: 170px;
}

.mathquill4quill-operator-button {
  margin: 5px;
  min-height: 50px;
  height: auto !important;
  min-width: 50px;
  width: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
  border-color: #1b1f2326;
  border-radius: 6px;
  border-width: 1px;
  cursor: pointer;
}

.mathquill4quill-operator-button:hover {
  border-color: #0366d6;
  box-shadow: rgba(27, 31, 35, 0.04) 1px 1px 1px 1px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
}

.mathquill4quill-button {
  margin: 5px;
  min-width: 90px;
  height: 26px;
  background-color: #f5f5f5;
  border-color: #000;
  border-radius: 7px;
  border-width: 1px;
}

.mathquill4quill-operator-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  border-color: #000;
  border: 3px;
  max-width: 750px;
}
.mathquill4quill-symbol-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
}

.mathquill4quill-history-button {
  overflow: hidden;
  margin: 5px;
  width: 270px;
  height: 65px;
  min-height: 60px;
  background-color: #fff;
  border-color: #000;
  border-radius: 7px;
  border-width: 2px;
  cursor: pointer;
  transition: background-color 0.3s linear;
  opacity: 1;
}

.mathquill4quill-history-button:hover {
  background-color: rgb(239, 240, 241);
  opacity: 0.7;
  border-color: #0366d6;
  box-shadow: rgba(27, 31, 35, 0.04) 1px 1px 1px 1px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
}

.mathquill4quill-tabs-button {
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  margin: 10px 10px 5px 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #0366d6;
  background-color: #fafbfc;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}

.mathquill4quill-tabs-button:hover {
  color: #ffffff;
  background-color: #0366d6;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.1) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.03) 0px 1px 0px 0px inset;
  transition-duration: 0.1s;
}
.mathquill4quill-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 150px;
  overflow: auto;
  margin: 10px;
  border-color: #000;
  border: 3px;
}

.mathquill4quill-latex-input {
  visibility: hidden !important;
  padding: 0 !important;
  border: 0 !important;
  width: 0 !important;
}
