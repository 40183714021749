@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --color-primary: 255 158 70;
    --color-secondary: 111 114 185;
    --color-primary-hover: 252 121 0;
    --color-primary-light: 255 239 224;
    --color-bgform: 243 243 243;
    --color-bgform-hover: 238 237 237;
    --color-blackcolor: 33 33 33;
    --color-grey-border: 188 188 188;
    --color-danger: 255 17 33;
    --color-danger-light: 255 229 231;
    --color-grey-text: 121 121 121;
  }
}
@font-face {
  font-family: Outfit-Regular;
  src: url("./assets/Fonts/Outfit-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: Outfit-SemiBold;
  src: url("./assets/Fonts/Outfit-SemiBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: Outfit-Medium;
  src: url("./assets/Fonts/Outfit-Medium.ttf");
  font-display: swap;
}

a,
label,
th,
td,
tr,
ul,
li,
ol,
input,
textarea,
select {
  font-family: Outfit-Regular !important;
}

// span {
//   font-family: Outfit-Regular ;
// }
h1 {
  font-family: Outfit-Medium !important;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: Outfit-SemiBold !important;
}

.Outfit-Regular {
  font-family: Outfit-Regular !important;
}
.Outfit-Medium {
  font-family: Outfit-Medium !important;
}
.Outfit-SemiBold {
  font-family: Outfit-SemiBold !important;
}

body {
  background-image: url("./assets/Images/bgImages/body-bg.png"), linear-gradient(to right, #ffffff, #fbfbfb);
  background-position: right top;
  background-repeat: no-repeat;
  min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 4px solid #ffffff;
  background-clip: padding-box;
  background-color: #d0d0d0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fc7900;
}

tbody td {
  color: #797979 !important;
}
.-translate-y {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-1-c {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-3-c {
  --tw-translate-x: 14px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-4-c {
  --tw-translate-x: 22px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.switch-on {
  background-image: url("./assets/Images/Icons/switch-on.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.switch-off {
  background-image: url("./assets/Images/Icons/switch-off.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

select:not([size]) {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="none" viewBox="0 0 10 6"><path stroke="%23212121" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/></svg>');
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.75em 0.75em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

select {
  appearance: none;
}

.LoginPage {
  background-image: url("./assets/Images/bgImages/LoginBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.pages-header-Icons{

  width:2.125rem;
   height:2.125rem;
}
.counter-page-Images{
width:2.5rem;
height:4.5rem;
}
.MuiSelect {
  .MuiInputBase-root {
    padding: 3px !important;
    background-color: rgb(var(--color-bgform));
    border-radius: 0.25rem;
    border-width: 0px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    &:focus {
      border-color: transparent;
    }
  }
  .MuiChip-root {
    border-radius: 5px;
    background-color: #797979;
    color: white;
    .MuiChip-deleteIcon {
      fill: white;
    }
  }
}
.MuiSelect-Single{
  .MuiSelect-select{
    padding: 10.5px 7px 10.5px 8px !important;
    background-color: rgb(var(--color-bgform));
    border-radius: 0.25rem;
    border-width: 0px !important;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: transparent !important;
  }
}
.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  width: calc(100% - 65px) !important;
}
#menu-appbar{
  .MuiPaper-root{
    box-shadow: 0 10px 20px 0 #e8e6ee;
    min-width: 200px;
  }
}
#Impact{
  .MuiPaper-root{
    box-shadow: 0 10px 20px 0 #e8e6ee;
  }
}
.Cart-ButtonActive{
  background-color: #ffefe0 !important;
  fill: #ff9e46 !important;
}
.SideNavBarOpen {
  //  width 
  ::-webkit-scrollbar {
    width: 12px;
  }
  //  Handle 
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 4px solid #ffffff00;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.3);
  }
  //  Handle on hover 
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border: 4px solid #ffffff00;
  }
.MuiListItemButton-root{
  border-left: 6px solid transparent;
}
.MuiListItemButton-root.active{
  border-left-color:white;
  .MuiTypography-root{
    color: #ffffff !important;
    font-weight: 500 !important;
  }
  .MuiListItemIcon-root{
  fill: #ffffff !important;
  stroke: #ffffff !important;
  }
}

  .MuiPaper-root {
    background-image: url("./assets/Images/bgImages/Open-sidebar.png");
    border-right-width: 0px;
    box-shadow: 10px 0px 20px 0 #f5f2fd;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .MuiDrawer-paper {
    overflow: visible !important;
  }
  .NavbarTogglebtn {
    transform: translateX(0.75rem);
    position: absolute;
    right: 0;
    top: 75px;
    padding: 0 !important;
  }

  .MuiListItemButton-root:hover{
    // border-left-color:white;
    .MuiTypography-root{
      color: #ffffff !important;
      font-weight: 500 !important;
    }
    .MuiListItemIcon-root{
    fill: #ffffff !important;
    stroke: #ffffff !important;
    }
  }

  .SubMenuActive{
    .MuiTypography-root{
      color: #ffffff !important;
      font-weight: 500 !important;
    }
    .MuiListItemIcon-root{
    fill: #ffffff !important;
    }
  }

}
.SideNavBarClose {
  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 4px solid #ffffff00;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.3);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border: 4px solid #ffffff00;
  }

  .MuiListItemButton-root{
    border-left: 6px solid transparent;
  }
  .MuiListItemButton-root.active{
    border-left-color:white;
    .MuiListItemIcon-root{
    fill: #ffffff !important;
    stroke: #ffffff !important;
    }
  }

  .MuiPaper-root {
    background-image: url("./assets/Images/bgImages/Close-sidebar.png");
    border-right-width: 0px;
    box-shadow: 10px 0px 20px 0 #f5f2fd;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .MuiDrawer-paper {
    overflow: visible !important;
  }
  .NavbarTogglebtn {
    transform: translateX(0.75rem);
    position: absolute;
    right: 0;
    top: 75px;
    padding: 0 !important;
  }
  .MuiListItemButton-root:hover{
    // border-left-color:white;
    .MuiTypography-root{
      color: #ffffff !important;
      font-weight: 500 !important;
    }
    .MuiListItemIcon-root{
    fill: #ffffff !important;
    stroke: #ffffff !important;
    }
  }

  .SubMenuActive{
    .MuiTypography-root{
      color: #ffffff !important;
      font-weight: 500 !important;
    }
    .MuiListItemIcon-root{
    fill: #ffffff !important;
    }
  }
}

.borderBottom-c {
  background-image: linear-gradient(to right, #9393af 44%, transparent 0%);
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
}
.MuiTablePagination-root {
  .MuiTablePagination-select {
    background-color: #faf9ff !important;
    border-radius: 5px !important;
    width: 40px !important;
    text-align: left !important;
    -webkit-text-align-last: left !important;
    text-align-last: left !important;
  }
  .MuiIconButton-root {
    background-color: #faf9ff !important;
    margin-right: 5px !important;
  }
}
.MuiTableCell-root{
  padding-top:12px !important;
  padding-bottom: 12px !important;
}
.Access-Privileges-Tables {
  .MuiCheckbox-root {
    padding: 0px !important;
  }
}
.ql-tooltip {
  left: 0 !important;
  z-index: 10;
}

.ql-toolbar.ql-snow{
  border: none!important;
  border-bottom: 1px solid rgb(var(--color-grey-text)) !important;
  background-color:  rgb(var(--color-bgform)) ;
  padding: 12px !important;
  border-top-right-radius: 5px ;
  border-top-left-radius: 5px ;
}
.ql-container.ql-snow{
  border: none!important;
}
.ql-editor{
// min-height: 14rem !important;
background-color:  rgb(var(--color-bgform)) !important;
border-bottom-right-radius: 5px ;
border-bottom-left-radius: 5px ;
}
// .ql-editor.ql-blank::before{
  // color: rgb(var(--color-grey-border)) !important;
  // font-style: normal !important;
// }

.ql-snow a, .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected{
  color: rgb(var(--color-primary)) !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter{
  stroke: rgb(var(--color-primary)) !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill{

  fill: rgb(var(--color-primary)) !important;
}
.mq-editable-field.mq-focused{
  box-shadow: none !important;
    border-color: rgb(var(--color-primary)) !important;
}

.mathquill4quill-tabs-button{
  color: rgb(var(--color-primary)) !important;
  background-color: rgb(var(--color-primary-light)) !important;
  border-color: rgb(var(--color-primary-light)) !important;
}
.mathquill4quill-tabs-button:hover {
  color: #ffffff !important;
  background-color: rgb(var(--color-primary)) !important;
  border-color: rgb(var(--color-primary)) !important;
}
.mathquill4quill-operator-button:hover {
  border-color: rgb(var(--color-primary)) !important; 
}

// h-4   h-3   h-6   h-5
.w-7-c{
 width: 28px;
}
.w-3-c{
  width: 12px;
}
.w-11-c{
  width: 44px;
}
.w-5-c{
  width: 20px;
}

.h-4-c{
height:16px ;
}
.h-3-c{
  height:12px ;
  }
  .h-6-c{
    height:24px ;
    }
    .h-5-c{
      height:20px ;
      }
.top-1-c{
  top: 4px;
}

.PDF-Page-Counter .MuiBadge-badge{
top: -8px;
right: -8px;
}
.px-27{
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}
// .ErrorPagePic{
//   height: 200px;
//   width: auto;
// }